import React, { useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { BASE_URL } from '../../lib/common'

const modelUrl = BASE_URL + "/static/media/LoadingTOP.glb";

export const LoaderTop = (props) => {
  const group = React.useRef()
  const { nodes, materials } = useGLTF(modelUrl, true)
  useEffect(() => {
    return () => {
      useGLTF.clear(modelUrl)
    }
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Empty" position={[0.003, 1.374, 0.191]} scale={0.046}>
          <group name="Circle004" position={[-0.212, -1.848, 1.114]} rotation={[1.571, 1.522, -Math.PI]} scale={[0.477, 4.412, 0.238]}>
            <mesh name="Circle005" geometry={nodes.Circle005.geometry} material={materials['Material.013']} />
            <mesh name="Circle005_1" geometry={nodes.Circle005_1.geometry} material={materials.Material} />
            <mesh name="Circle005_2" geometry={nodes.Circle005_2.geometry} material={materials['Material.009']} />
            <mesh name="Circle005_3" geometry={nodes.Circle005_3.geometry} material={materials['Material.005']} />
            <mesh name="Circle005_4" geometry={nodes.Circle005_4.geometry} material={materials['Material.002']} />
            <mesh name="Circle005_5" geometry={nodes.Circle005_5.geometry} material={materials['Material.003']} />
            <mesh name="Circle005_6" geometry={nodes.Circle005_6.geometry} material={materials['Material.004']} />
            <mesh name="Circle005_7" geometry={nodes.Circle005_7.geometry} material={materials['Material.010']} />
            <mesh name="Circle005_8" geometry={nodes.Circle005_8.geometry} material={materials['Material.011']} />
            <mesh name="Circle005_9" geometry={nodes.Circle005_9.geometry} material={materials['Material.012']} />
            <mesh name="Circle005_10" geometry={nodes.Circle005_10.geometry} material={materials['Material.006']} />
            <mesh name="Circle005_11" geometry={nodes.Circle005_11.geometry} material={materials['Material.007']} />
            <mesh name="Circle005_12" geometry={nodes.Circle005_12.geometry} material={materials['Material.008']} />
            <mesh name="Circle005_13" geometry={nodes.Circle005_13.geometry} material={materials['Material.014']} />
            <mesh name="Circle005_14" geometry={nodes.Circle005_14.geometry} material={materials['Material.015']} />
            <mesh name="Circle005_15" geometry={nodes.Circle005_15.geometry} material={materials['Material.016']} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(modelUrl)
