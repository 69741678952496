import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useGLTF } from "@react-three/drei";
import { DoubleSide } from "three";
import { dispose } from "@react-three/fiber";
import { disposeCurrentItem } from "../../lib/disposeGltf";
import { Loader, LoaderBottom, LoaderTop } from "../Loader";
// import { GLTFLoader, DRACOLoader } from 'three-stdlib'

// const gltfLoader = new GLTFLoader()
// const dracoloader = new DRACOLoader()
// dracoloader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
// gltfLoader.setDRACOLoader(dracoloader)

// THREE.cache.enabled = false;

const adjustMaterialProperties = (
  nodes,
  defaultEnvExposure,
  isHDRLighting = false
) => {
  Object.values(nodes).forEach((node) => {
    if (node.material) {
      if (isHDRLighting && node.isMesh) {
        node.material.envMapIntensity = defaultEnvExposure;
      }
      node.material.side = DoubleSide;
      if (
        node.name !== null &&
        node.name !== undefined &&
        node.name.startsWith("Trim") &&
        node.isMesh
      ) {
        node.material.metalness = 0.7;
        node.material.roughness = 0.2;
        node.material.clearcoat = 1;
      }
    }
  });
};

const Model = ({ src, colorCode }) => {
  const gltf = useGLTF(src, true);
  const { nodes } = gltf;
  const dontChangeList = useRef(new Set());
  useEffect(() => {
    return () => {
      disposeCurrentItem(gltf);
    //   dispose(gltf.scene)
      useGLTF.clear(src);
      dontChangeList.current.clear();
    };
  }, [src, gltf]);

  useLayoutEffect(() => {
    if (colorCode) {
      if (gltf?.scene?.traverse) {
        gltf?.scene?.traverse((child) => {
          if (
            child.name !== null &&
            child.name !== undefined &&
            (child.name.startsWith("Trim") || child.name.startsWith("MatShape"))
          ) {
            if (child.isMesh) {
              dontChangeList.current.add(child.name);
            }
            if (
              child.children?.length !== 0 &&
              child.children[0].name !== null &&
              child.children[0].name !== undefined
            ) {
              dontChangeList.current.add(child.children[0].name);
            }
          }
          if (child.isMesh && !dontChangeList.current.has(child.name)) {
            child.material.color.set(colorCode);
          }
        });
      }
    }
  }, [colorCode, gltf]);

  useMemo(() => {
    adjustMaterialProperties(nodes);
  }, [nodes]);
  return (
      <primitive object={gltf.scene} />
  );
};

const MemoizedModel = React.memo(Model);

const Garment = ({ dressUrl, targetColorCode, isTop = true }) => {
  const colorKey = useMemo(() => {
    return isTop ? "topColor" : "bottomColor";
  }, [isTop]);

  const colorCode = useMemo(() => {
    if (targetColorCode) {
      const refreshStartedSess = sessionStorage.getItem("refreshStartedSess");
      if (!refreshStartedSess) {
        sessionStorage.setItem(colorKey, targetColorCode);
      }
      return targetColorCode;
    } else {
      return sessionStorage.getItem(colorKey, targetColorCode);
    }
  }, [targetColorCode, colorKey]);

  const Loader = useMemo(() => isTop ? LoaderTop : LoaderBottom)
  return (
    <Suspense fallback={<Loader />}>
      <MemoizedModel src={dressUrl} colorCode={colorCode} />
    </Suspense>
  );
};

export default Garment;
