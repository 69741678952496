import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import Garment from "../Garment/Garment";
import { Avatar } from "../Avatar";

const TIME_STEP = 0.002; // Alternate rotations for 3d avatar & garments
const ROTATION_ANGLE = 0.3; //Angle of rotation for 3d models

const ModelRenderer = ({
  upperDressUrl,
  lowerDressUrl,
  storeName,
  toggleAvatar,
  colorTop,
  colorBottom,
}) => {
  const rotationState = useRef(1);
  let isClockwise = useRef(false);
  const viewerRef = useRef(null);

  useFrame(() => {
    if (rotationState.current === 3) {
      return false;
    } else if (rotationState.current === 2) {
      if (viewerRef.current) {
        if (isClockwise) {
          viewerRef.current.rotation.y += TIME_STEP;
          if (viewerRef.current.rotation.y > ROTATION_ANGLE) {
            isClockwise = false;
          }
        }
        if (!isClockwise) {
          viewerRef.current.rotation.y -= TIME_STEP;
          if (viewerRef.current.rotation.y < -ROTATION_ANGLE) {
            isClockwise = true;
          }
        }
      }
    } else if (rotationState.current === 1) {
      return true;
    }
  });

  return (
    <mesh ref={viewerRef} position={[0, -2.8, 0]} scale={2.8} dispose={null}>
      {toggleAvatar && <Avatar storeName={storeName} />}
      {upperDressUrl && <Garment dressUrl={upperDressUrl} targetColorCode={colorTop} isTop />}
      {lowerDressUrl && <Garment dressUrl={lowerDressUrl} targetColorCode={colorBottom} isTop={false} />}
    </mesh>
  );
};

export default ModelRenderer;
